
































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { v4 as uuid } from "uuid";
import agent from "@/helpers/agent";
import {
  BusinessDocumentType,
  DocumentProfile,
} from "@/models/businessDocument";
import ManagePdf from "@/components/ManagePdf.vue";
import { namespace } from "vuex-class";
import { ProfileDocument } from "@/models/businessDocument";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";
import { BusinessType } from "@/models/businessApplication";

const app = namespace("app");

interface ProfileStatus {
  id: number;
  name: string;
  status: string;
}

@Component({
  components: {
    ManagePdf,
  },
})
export default class BusinessProfile extends Vue {
  @app.Getter authHeader!: any;

  step = 1;
  coverLetter: File | null = null;
  businessPlan: File | null = null;
  cashFlowProjection: File | null = null;
  profileDocuments: ProfileDocument[] = [];
  type: BusinessType = BusinessType.SoleTrader;

  moveToNextStep = true;

  loading = true;
  data: ProfileStatus[] = [];
  get coverLetterType() {
    return BusinessDocumentType.CoverLetter;
  }
  get businessPlanType() {
    return BusinessDocumentType.BusinessPlan;
  }
  get financialStatementsType() {
    return BusinessDocumentType.FinancialStatements;
  }
  get cashflowProjectionsType() {
    return BusinessDocumentType.CashflowProjections;
  }
  get isCompany() {
    return this.type === BusinessType.Enterprise;
  }
  documentExists(type: BusinessDocumentType) {
    const index = this.profileDocuments.findIndex((x) => x.type === type);
    return index > -1 ? true : false;
  }
  getDocumentId(type: BusinessDocumentType) {
    const document = this.profileDocuments.find((x) => x.type === type);
    return document ? document.id : null;
  }
  getDocumentAuthor(type: BusinessDocumentType) {
    const document = this.profileDocuments.find((x) => x.type === type);
    return document ? `By ${document.authorName}` : null;
  }
  getFields(type: BusinessDocumentType, profileOrder: number) {
    return [
      {
        name: "type",
        value: type,
      },
      {
        name: "documentProfile",
        value: DocumentProfile.Business,
      },
      {
        name: "profileOrder",
        value: profileOrder,
      },
      {
        name: "applicationId",
        value: this.$route.query.id as string,
      },
      {
        name: "documentId",
        value: uuid(),
      },
    ];
  }
  routeToProfiles() {
    this.$router.push({
      name: "ProfilesApplication",
      query: { ...this.$route.query },
    });
  }
  getProfileDocuments() {
    if (this.$route.query.id) {
      this.type = Number(this.$route.query.type);
      agent.BusinessDocuments.getProfileDocuments(
        this.$route.query.id as string,
        DocumentProfile.Business
      ).then((documents) => {
        this.loading = false;
        this.profileDocuments = documents;
      });
    }
  }
  mounted() {
    AppEventBus.$on(AppEventBusEvents.changeStatusEvent, () => {
      if (this.step > 3) this.routeToProfiles();
      this.step++;
    });
    AppEventBus.$on(AppEventBusEvents.deleteDocumentEvent, (id: string) => {
      this.profileDocuments = this.profileDocuments.filter((x) => x.id != id);
    });
    this.getProfileDocuments();
  }
  beforeDestroy() {
    AppEventBus.$off();
  }
}
